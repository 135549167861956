import React from 'react';
import './spinner.css';

const Spinner = () => {
    return (
        <div className="loadingio-spinner-spin-9unx9b0o0rg">
            <div className="ldio-lr7thn6n79a">
                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div>
        </div>
    )
}
export default Spinner;